import {useEffect, useState} from 'react';

const PHRASES = [
  'What to eat ah?',
  'Ready to fuel your cravings?',
  'Time to satisfy your taste buds!',
  'Ready to explore new flavours?',
  "Let's grab a quick bite!",
  'Eh, makan apa ni?',
  'Hungry liao, what to eat?',
  'Order lah, no need to cook',
];

const useRandomPhrase = () => {
  const [phrase, setPhrase] = useState(getRandomPhrase());

  function getRandomPhrase() {
    return PHRASES[Math.floor(Math.random() * PHRASES.length)];
  }

  useEffect(() => {
    const interval = setInterval(() => {
      let newPhrase = '';
      while (true) {
        const newRandomPhrase = getRandomPhrase();
        if (phrase === newRandomPhrase) continue;
        newPhrase = newRandomPhrase;
        break;
      }
      setPhrase(newPhrase);
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [phrase]);

  return {phrase};
};

export default useRandomPhrase;
