import React, {useEffect, useState} from 'react';
import {GetServerSideProps} from 'next';
import Link from 'next/link';
import Image from 'next/image';
import {useRouter} from 'next/router';
import {useLoader} from 'context/loaderContext';
import {useGeolocation} from 'context/geolocationContext';
import {DeliveryOption, useGetPopOutImagesQuery} from '@graphql/generated/graphql';
import {PageGetMeComp, ssrGetMe} from '@graphql/generated/page';
import {MixOrdersOngoing, Spinner} from '@components/index';
import {useMixOrderStore} from 'stores';
import {useRandomPhrase} from 'hooks';

import deliveryOptionMapping from '@lib/helpers/delivery-option-mapping';
import NoticeModal from '@components/Layout/NoticeModal';
import SearchOutlets from '@components/Search/SearchOutlets';
import BottomNavbar from '@components/Layout/BottomNavbar';

export const getServerSideProps: GetServerSideProps = async ctx => {
  const {props} = await ssrGetMe.getServerPage({}, ctx);
  return {props};
};

const Home: PageGetMeComp = ({data: {me}}) => {
  const router = useRouter();
  const deliveryChoice = router.query.deliveryOption as string;

  const {phrase} = useRandomPhrase();
  const {address, loading: geolocationLoading} = useGeolocation();
  const {setShowLoader} = useLoader();

  const [deliveryOption, setDeliveryOption] = useState<DeliveryOption>();

  const cart = useMixOrderStore(s => s.cart);
  const cartSize = Object.values(cart).filter(({stores}) => !!stores.length).length;

  const {data: mainPopUpImageData} = useGetPopOutImagesQuery();
  const mainPopUpImage = mainPopUpImageData?.popUpImages?.[0];

  useEffect(() => {
    if (!router.isReady) return;
    setDeliveryOption(deliveryOptionMapping(deliveryChoice));
  }, [router.isReady]);

  if (!deliveryOption) {
    return null;
  }
  return (
    <main className="bg-bgColor pb-20">
      <section className="sticky top-0 left-0 right-0 max-w-md mx-auto w-full bg-transparent z-70">
        <div className="relative bg-black-doodles h-36 rounded-b-3xl">
          <div className="absolute w-full h-36 top-0 left-0 z-0">
            <Image src="/doodles.png" alt="home_doodles" width={500} height={160} />
          </div>
          <div className="absolute flex flex-col justify-between items-center w-full h-36 paddingHorizontal">
            <div className="flex flex-row jusitfy-between items-center w-full flex-1">
              <div className="text-primary poppins-semibold text-2xl flex-1 pr-2">{phrase}</div>
              <div className="flex flex-row justify-between items-center self-center">
                <div className="mr-4 mt-2">
                  <Link href="/search">
                    <a onClick={() => setShowLoader(true)}>{SEARCH_SVG}</a>
                  </Link>
                </div>
                <Link href="/cart" passHref>
                  <a className="flex flex-row justify-between items-center px-2 py-1.5 rounded-md bg-primary" onClick={() => setShowLoader(true)}>
                    {CART_SVG}
                    {!!cartSize && <p className="text-sm text-white poppins-medium font-sm ml-2.5">{cartSize}</p>}
                  </a>
                </Link>
              </div>
            </div>
            <Link href="/ordering-location">
              <a className="flex flex-row items-center w-full mb-4" onClick={() => setShowLoader(true)}>
                <Image src="/icons/location-pin-icon.svg" width={15} height={15} />
                <p className="flex-1 poppins-medium text-white truncate mx-2">{address || 'Please select a location...'}</p>
                {CHEVRON_RIGHT}
              </a>
            </Link>
          </div>
        </div>
      </section>
      <section className="pb-20 mt-5">
        {geolocationLoading ? (
          <Spinner />
        ) : (
          <>
            <NoticeModal data={mainPopUpImage} />
            <SearchOutlets />
            {me && <MixOrdersOngoing />}
            <BottomNavbar />
          </>
        )}
      </section>
    </main>
  );
};

export default Home;

const SEARCH_SVG = (
  <svg width="20" height="20" className="mb-2" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.7955 13.8111L19 19M16 8.5C16 12.6421 12.6421 16 8.5 16C4.35786 16 1 12.6421 1 8.5C1 4.35786 4.35786 1 8.5 1C12.6421 1 16 4.35786 16 8.5Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CART_SVG = (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 1L1.23547 1.07849C2.40884 1.46962 2.99552 1.66517 3.33108 2.13075C3.66665 2.59632 3.66665 3.21474 3.66665 4.45157V6.77775C3.66665 9.29187 3.66665 10.5489 4.4477 11.33C5.22874 12.1111 6.48581 12.1111 8.99996 12.1111H16.111"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <path
      d="M5.88887 14.3333C6.62525 14.3333 7.2222 14.9302 7.2222 15.6666C7.2222 16.4029 6.62525 16.9999 5.88887 16.9999C5.15249 16.9999 4.55554 16.4029 4.55554 15.6666C4.55554 14.9302 5.15249 14.3333 5.88887 14.3333Z"
      stroke="white"
      strokeWidth="1.2"
    />
    <path
      d="M13.8889 14.3334C14.6252 14.3334 15.2222 14.9303 15.2222 15.6667C15.2222 16.4031 14.6252 17 13.8889 17C13.1525 17 12.5555 16.4031 12.5555 15.6667C12.5555 14.9303 13.1525 14.3334 13.8889 14.3334Z"
      stroke="white"
      strokeWidth="1.2"
    />
    <path
      d="M3.66663 3.66663H13.8447C15.6714 3.66663 16.5846 3.66663 16.9799 4.26597C17.3751 4.86531 17.0153 5.70478 16.2957 7.38376L15.9147 8.27265C15.5788 9.05647 15.4108 9.44838 15.0769 9.66864C14.7429 9.88882 14.3165 9.88882 13.4637 9.88882H3.66663"
      stroke="white"
      strokeWidth="1.2"
    />
  </svg>
);

const CHEVRON_RIGHT = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#ffffff" className="h-4 w-4 mb-0.5 ml-0.5">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M9 5l7 7-7 7" />
  </svg>
);
